import React, {setGlobal} from 'reactn'; 
import Modal from "react-bootstrap/Modal";
import SelectSearch from 'react-select-search';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { DataStore } from "@aws-amplify/datastore";
import { UserSettings } from "../models";
import ApiHelper from './ApiHelper.js';
import {matchSorter} from 'match-sorter';
const api = new ApiHelper();

const PlaylistModal = (props) => {    
     
    return(
                <Modal show={props.visible} onHide={ props.togglePlaylistModal } className="playlist-modal tss-modal">
                    <Modal.Header>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                        <input id="fk_playlist_id" type="hidden" value={props.item.id}></input>
                        <label>Name: </label><br/>
                        <input id="playlist_name" type="text" defaultValue={props.item.name}></input><br/>
                        { props.type === "artist" ? <div>
                            <label>Included Artists: </label> 
                            <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Add</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="search">
                                                <SelectSearch 
                                                    options={props.all_artists || []}
                                                    search
                                                    placeholder="Add an artist..."
                                                    onChange={props.updatePlaylistItem.bind(this, "add", "artist")}
                                                    fuzzySearch
                                                    filterOptions={[(options) => {  
                                                                                                    
                                                        const filter = options || []
                                                        return filter.slice(0, 100);
                                                    }]}
                                                    getOptions={inputValue => {
                                                        if (props.all_artists){
                                                        return matchSorter(props.all_artists, inputValue, {keys: ['name']})
                                                    }
                                                    else {
                                                        return []
                                                    }
                                                      }}
                                                    closeOnSelect
                                                    printOptions="on-focus"
                                                />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            </Accordion>
                            { props.item.artists ? 
                                <ul className="playlist-items-list">
                                { (props.item.artists || []).map((item) => {
                                    return (
                                        <li key={item.fk_artist_id}>                                
                                            <span> {item.youtube_name} </span>
                                            <button onClick={props.updatePlaylistItem.bind(this, "remove","artist", item)}>Remove</button>                                
                                        </li>
                                    );
                                })}
                                </ul> : <ul></ul>
                            }                
                            
                        </div> : <div/>
                        }
                        { props.type === "genre" ? 
                        <div>
                            <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Add</Accordion.Header>
                                        <Accordion.Body>
                                        <div className="search">
                                            <SelectSearch 
                                                options={props.all_genres || []}
                                                search
                                                placeholder="Add an genre..."
                                                onChange={props.updatePlaylistItem.bind(this, "add","genre")}
                                                fuzzySearch
                                                filterOptions={[(options) => {  
                                                                                                                
                                                    const filter = options || []
                                                    return filter.slice(0, 100);
                                                }]}
                                                getOptions={inputValue => {
                                                    if (props.all_genres){
                                                    return matchSorter(props.all_genres || [], inputValue, {keys: ['name']})
                                                }else {
                                                    return []
                                                }
                                                  }}
                                                closeOnSelect
                                                printOptions="on-focus"
                                            />
                                        </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            </Accordion>
                            
                            <label>Included Genres: </label> 
                            { props.item.genres ? 
                                <ul className="playlist-items-list">
                                        {(props.item.genres  || []).map((item) => {
                                            return (
                                                <li key={item.fk_genre_id}  > 
                                                    
                                                    <span> {item.genre_name} </span><button onClick={props.updatePlaylistItem.bind(this, "remove","genre", item)}>Remove</button>
                                                    
                                                </li>
                                            );
                                        })
                                    }</ul> : <ul></ul>
                                }               
                            
                        </div> : <div/>
                    }                       
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-secondary" onClick={props.togglePlaylistModal}>Cancel</Button>
                        { props.item.id ? <Button className="btn-secondary" onClick={props.deletePlaylist}>Delete</Button> : <div/>}
                        <Button className="btn-primary" onClick={props.savePlaylist}>Save</Button>
                    </Modal.Footer> 
                </Modal>

    )
}
class ListPlaylist extends React.Component{
    default_item= {
        'name': '',
        'youtube_id': '',
        'id': ''
    }
    state = {
          active: false,
          selected_item: this.default_item
    }

    togglePlaylistModal = (item, event) => {
        let active = this.state.active;
        console.log(item)       

        if (this.props.editable){
            this.setState({
                active: !active,
                selected_item: item || this.default_item
            });
        }
    }
    togglePlaylist = (item, e) => {        
        e.stopPropagation();
        let settings = this.global.settings;
        let playing = !item.playing;
        let playing_playlist = item
        if (!playing){
            playing_playlist = null
        }
        DataStore.save(
            UserSettings.copyOf(settings, updated => {
                updated.selected_genres = [];
            })
        );        
        let genre_playlists = this.global.genre_playlists;
        genre_playlists.forEach(function(el) {
            el.playing = false;
            if (el.id === item.id) {
                el.playing = playing;
            }
        })
        let artist_playlists = this.global.artist_playlists;
        artist_playlists.forEach(function(el) {
            el.playing = false;
            if (el.id === item.id) {
                el.playing = playing;
            }
        })
        let auto_playlists = this.global.auto_playlists;
        auto_playlists.forEach(function(el) {
            el.playing = false;
            if (el.fk_seed_id === item.fk_seed_id) {
                el.playing = playing;
            }
        })            
        setGlobal({            
            "playing_genres" : [],
            "playing_tag": null,
            "playing_playlist": playing_playlist,
            "genre_playlists": genre_playlists,
            "artist_playlists": artist_playlists,
            "auto_playlists": auto_playlists
        })
        this.props.fetchVideos(true);    
    }
    updatePlaylistItem = (action, item_type,item) =>   { 
        let playlist = this.state.selected_item       
        if (item_type === "artist"){            
            let artist_name = this.global.all_artists.find(obj => {
                return obj.value === item
            })            
            if (!playlist.artists) {
                playlist.artists = []
            }
            if (action === "add"){
                playlist.artists.push({
                    "youtube_name": artist_name['name'],
                    'fk_artist_id': item
                })  
            } else{
                playlist.artists = playlist.artists.filter(function(el) {
                    return el.fk_artist_id !== item.fk_artist_id
                })
            }           
        }
        else if  (item_type === "genre"){            
            let genre_name = this.global.all_genres.find(obj => {
                return obj.value === item
            })            
            if (!playlist.genres) {
                playlist.genres = []
            }
            if (action === "add"){
                playlist.genres.push({
                    "genre_name": genre_name['name'],
                    'fk_genre_id': item
                })  
            } else{
                playlist.genres = playlist.genres.filter(function(el) {
                    return el.fk_genre_id !== item.fk_genre_id
                })
            }           
        }
        this.setState({selected_item : playlist, active: true})        
    }
    savePlaylist = async() =>  {
        let item = this.state.selected_item;
        item.name = document.getElementById("playlist_name").value;
        let playlist_type = this.props.type        
        let result = await api.savePlaylist(this.global.user, item, playlist_type, false); 
        this.togglePlaylistModal();       
        if (playlist_type === "genre"){
            result = await api.getGenrePlaylists(this.global.user);
            setGlobal({ 
                genre_playlists: result
            }) 
        }    
        else if (playlist_type === "artist"){
            result = await api.getArtistPlaylists(this.global.user);
            setGlobal({ 
                artist_playlists: result
            }) 
        } 
        this.setState({selected_playlist: this.default_item})
        
        

    }
    deletePlaylist = async() =>  {
        let item = this.state.selected_item;
        item.name = document.getElementById("playlist_name").value;
        let playlist_type = "auto"
        if (item.hasOwnProperty("genres")){
            playlist_type = "genre";
        }
        if (item.hasOwnProperty("artists")){
            playlist_type = "artist";
        }
        let result = await api.savePlaylist(this.global.user, item, playlist_type, true);        
        if (playlist_type === "genre"){
            result = await api.getGenrePlaylists(this.global.user);
            setGlobal({ 
                genre_playlists: result
            }) 
        }    
        else if (playlist_type === "artist"){
            result = await api.getArtistPlaylists(this.global.user);
            setGlobal({ 
                artist_playlists: result
            }) 
        } 
        this.togglePlaylistModal()
        return true;     
    }
    
    render(){      
        let list_items = <div/>  
        if(this.props.items){
            list_items = (this.props.items || []).map((item) =>
                <li className="playlist-list-item-container" key={item.id||item.fk_video_id||item.fk_seed_id} >
                    <div className="playlist-list-item" style={{backgroundImage: "url(https://i.ytimg.com/vi/" + item.youtube_id + "/default.jpg)"}}>                
                        <div className="playlist-list-item-details" onClick={this.togglePlaylistModal.bind(this, item)}>                                
                            <div className="playlist-list-item-name">
                                <p>{item.name}</p>
                                <div className="playlist-list-item-playlist-button">{item.playing ? <Button onClick={this.togglePlaylist.bind(this, item)} variant="primary">Stop</Button> : <Button onClick={this.togglePlaylist.bind(this, item)} variant="dark">Play</Button>}</div>
                    
                            </div>
                            
                        </div>
                        </div>
                    </li>
            );
        } 
        return (
            <div>
                { this.props.editable ? <button onClick={this.togglePlaylistModal.bind(this, null)}>New Playlist</button> : <div/>}               
                
                <PlaylistModal all_artists={this.props.type === "artist" ? this.global.all_artists : []} all_genres={this.props.type === "genre" ? this.global.all_genres : []} type={this.props.type} togglePlaylistModal={this.togglePlaylistModal} updatePlaylistItem={this.updatePlaylistItem} savePlaylist={this.savePlaylist} deletePlaylist={this.deletePlaylist} visible={this.state.active} item={this.state.selected_item} title="Edit Playlist"/>
                <ul className="playist-list">{list_items}</ul>    
            </div>    
        )
    }
}
export default ListPlaylist;